import { Component, Vue } from 'vue-property-decorator'
// layouts
import layout from '@/layouts/basic/basic.vue';
import ContentWP from '@/components/contentWP/contentWP.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    layout,
    ContentWP
  }
})
export default class Pagina extends Vue {

  get typePage(){
    return this.$route.meta?.typePage
  }

}
  